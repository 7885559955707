import { getDoc, doc, onSnapshot } from "@firebase/firestore";
import { createCollection } from "../firebase";
import { User } from "../domain";

const usersCollection = createCollection<Omit<User, "userId">>("users");

export const getUser = async (userId: string) => {
  const snapshot = await getDoc(doc(usersCollection, userId));
  const user = snapshot.data();

  if (user) {
    return {
      userId,
      ...user,
    };
  }

  return null;
};

export const listenOnUser = (userId: string | null, callback: (user?: User) => void) => {
  if (userId) {
    return onSnapshot(doc(usersCollection, userId), async (user) => {
      callback({
        userId: user.id,
        ...(await user.data()),
      });
    });
  }
};
