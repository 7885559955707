import React from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

type Loading = false;
type LoggedOut = null;

export const loadingState: Loading = false;
export const loggedOutState: LoggedOut = null;

export const AuthContext = React.createContext<firebase.User | Loading | LoggedOut>(loadingState);
