import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { getAnalytics } from "firebase/analytics";
import { GoogleAuthProvider, GithubAuthProvider, connectAuthEmulator } from "firebase/auth";
import {
  getFirestore,
  CollectionReference,
  collection,
  DocumentData,
  connectFirestoreEmulator,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBtgUC2D-_gcEFyvHbMauZofDdspwIAYSk",
  authDomain: "skillbit-6c26c.firebaseapp.com",
  projectId: "skillbit-6c26c",
  storageBucket: "skillbit-6c26c.appspot.com",
  messagingSenderId: "691540575454",
  appId: "1:691540575454:web:fdc6326c855d8da1a9ee4f",
  measurementId: "G-H213PVC4XN",
};

const app = firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const analytics = getAnalytics(app);
export const googleAuthProvider = new GoogleAuthProvider();
export const githubAuthProvider = new GithubAuthProvider();
export const firestore = getFirestore();

if (process.env.REACT_APP_FIRESTORE_EMULATOR_HOST && process.env.REACT_APP_FIRESTORE_EMULATOR_PORT) {
  connectFirestoreEmulator(
    firestore,
    process.env.REACT_APP_FIRESTORE_EMULATOR_HOST,
    parseInt(process.env.REACT_APP_FIRESTORE_EMULATOR_PORT),
  );
}

if (process.env.REACT_APP_AUTH_EMULATOR_URL) {
  connectAuthEmulator(auth, process.env.REACT_APP_AUTH_EMULATOR_URL);
}

export const createCollection = <T = DocumentData>(collectionName: string) => {
  return collection(firestore, collectionName) as CollectionReference<T>;
};
