import React from "react";
import { BrowserRouter } from "react-router-dom";

import AppRoot from "./AppRoot";
import { AuthProvider } from "../../provider/AuthProvider";
import { UserProvider } from "../../provider/UserProvider";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { SnackbarProvider } from "notistack";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

export default function App(): JSX.Element {
  return (
    <ThemeProvider theme={darkTheme}>
      <SnackbarProvider
        anchorOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
      >
        <CssBaseline />
        <AuthProvider>
          <UserProvider>
            <BrowserRouter>
              <AppRoot />
            </BrowserRouter>
          </UserProvider>
        </AuthProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}
