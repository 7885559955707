import React, { useContext, useEffect, useState } from "react";
import "firebase/compat/auth";
import { getUser, listenOnUser } from "../repos/users";
import { defaultUser, User, UserContext } from "../context/UserContext";
import { AuthContext } from "../context/AuthContext";
import { User as BaseUser } from "../domain";

type Props = {
  children: JSX.Element;
};

export const UserProvider: React.FC<Props> = ({ children }) => {
  const auth = useContext(AuthContext);
  const [user, setUser] = useState<User>(defaultUser);

  const updateUser = (user: BaseUser | null) => {
    if (!user) {
      setUser({
        loaded: true,
        exists: false,
      });
    }

    if (user) {
      setUser({
        ...user,
        loaded: true,
        exists: true,
      });
    }
  };

  useEffect(() => {
    if (auth) {
      getUser(auth.uid).then((user) => {
        updateUser(user);
      });
    }

    if (auth === null) {
      setUser({
        loaded: true,
        exists: false,
      });
    }
  }, [auth]);

  useEffect(() => {
    return listenOnUser(auth ? auth.uid : null, (user) => {
      updateUser(user ?? null);
    });
  }, [auth]);

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};
