import React from "react";
import "firebase/compat/auth";
import { User as BaseUser } from "../domain";

export type User = Partial<BaseUser> & {
  loaded: boolean;
  exists: boolean;
};

export const defaultUser: User = {
  loaded: false,
  exists: false,
};

export const UserContext = React.createContext<User>(defaultUser);
