import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { CircularProgress, Container } from "@mui/material";
import NotFoundPage from "./NotFoundPage";

const HomePage = lazy(() => import("../home/HomePage"));
const LoginPage = lazy(() => import("../login/LoginPage"));
const DashboardRoot = lazy(() => import("../dashboard/DashboardRoot"));
const ConfigRoot = lazy(() => import("../config/ConfigRoot"));

export default function AppRoot(): JSX.Element {
  const Fallback = (
    <Container
      sx={{
        marginTop: 2,
        textAlign: "center",
      }}
    >
      <CircularProgress />
    </Container>
  );

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <React.Suspense fallback={Fallback}>
              <HomePage />
            </React.Suspense>
          }
        />
        <Route
          path="/login"
          element={
            <React.Suspense fallback={Fallback}>
              <LoginPage />
            </React.Suspense>
          }
        />
        <Route
          path="/dashboard/*"
          element={
            <React.Suspense fallback={Fallback}>
              <DashboardRoot />
            </React.Suspense>
          }
        />
        <Route
          path="/config/*"
          element={
            <React.Suspense fallback={Fallback}>
              <ConfigRoot />
            </React.Suspense>
          }
        />
        <Route path="/*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
}
