import React from "react";
import { useTitle } from "../../hooks/use-title";
import { APP_NAME } from "../../consts";
import { Box, Button, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export default function NotFoundPage() {
  useTitle(`404 Not found - ${APP_NAME}`);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Typography variant="h1">404</Typography>
        <Typography variant="h6">The page you are looking for does not exist.</Typography>
        <Button component={RouterLink} sx={{ marginTop: 2 }} to="/">
          Back to home
        </Button>
      </Box>
    </>
  );
}
